.menu {
	background-color: var(--primary-background-color);
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
	width: 0;
}

.menu.open {
	width: 250px;
}

.menu.close {
	width: fit-content;
	padding: 3rem 1rem 1rem 1rem;
}

.menu-list {
	margin: 0;
	padding: 0;
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.menu-item {
	width: 100%;
	padding: 5px 2rem;
	margin-bottom: 16px;
	color: var(--text-color-detail);
	font-weight: 500;
	font-size: 22px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	list-style: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}

.menu-item:hover {
	filter: brightness(1.4);
}

.selected-menu-item {
	color: var(--text-color-selected) !important;
	background: rgb(210, 0, 2);
	background: linear-gradient(
		90deg,
		var(--accent-color) -50%,
		var(--primary-background-color) 100%
	);
}

.button-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 15px 0px 15px;
}

.menu-icon {
  height: 25px;
  width: auto;
  cursor: pointer;
}

.menu-button {
	padding: 10px 40px;
	border-radius: 10px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: var(--accent-color);
	color: white;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	user-select: none;
}

.menu-button:hover, .menu-icon:hover {
	transform: scale(1.05);
	filter: brightness(1.08);
}
