.sign-input {
	background-color: var(--secondary-color);
	color: var(--text-color-secondary);
	outline: none;
	border: none;
	border-radius: 5px;
	padding: 15px;
	font-size: 1rem;
	width: 100%;
	user-select: none;
}

.error {
	border: 1px solid var(--accent-color);
}
