:root {
	--primary-background-color: #2f3136;
	--secondary-background-color: #202225;
	--secondary-color: #2f3444;
	--accent-color: #F64C72;
	--text-color-selected: #fff;
	--text-color-secondary: white;
	--text-color-detail: #aaaaae;
	--star-color: #ffda58;
	--font-primary: "Roboto", sans-serif;
	--font-secondary: "Nunito", sans-serif;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: var(--secondary-color) var(--secondary-background-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 16px;
}

*::-webkit-scrollbar-track {
	background: var(--secondary-background-color);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--secondary-color);
	border-radius: 10px;
	border: 3px solid var(--secondary-background-color);
}

body {
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}
