.year-selector {
	padding: 20px 0px 10px 0px
}

.words {
	color: var(--text-color-secondary);
	font-size: 25px;
	padding-left: 10px;
	padding-right: 10px;
}

.button {
	padding: 5px 10px;
	border-radius: 10px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: var(--accent-color);
	color: white;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	user-select: none;
}

.disabled {
	filter: brightness(0.5);
}
