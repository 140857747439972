.input-container {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

label {
	width: 100px;
	margin-right: 10px;
}
