.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: fit-content;
	width: 100%;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--secondary-background-color);
	color: var(--text-color-selected);
	font-family: var(--front-primary);
	box-shadow: 0px 0px 100px 10px rgba(246, 76, 114, 0.2);
	overflow: hidden;
	border: none;
	outline: none;
	border-radius: 15px;
	animation: modal-popup 200ms ease-in-out forwards;
}

@keyframes modal-popup {
	0% {
		transform: translate(-50%, -50%) scale(0.9);
		opacity: 0;
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
}

.fade-away {
	animation: fade-away 200ms ease-in-out forwards;
}

@keyframes fade-away {
	0% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
	100% {
		transform: translate(-50%, -50%) scale(0.9);
		opacity: 0;
	}
}

.modal-header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.modal-header > * {
	margin: 0;
}

.close-button {
	position: absolute;
  	top: 5px;
  	right: 5px;
  	background: none;
  	border: none;
  	font-size: 22px;
	font-weight: bold;
  	cursor: pointer;
	user-select: none;
	width: 30px;
	height: 30px;
	color: var(--text-color-selected);
}

.close-button:hover {
	transform: scale(1.15);
	filter: brightness(.7);
}

input {
	margin: 1rem;
}

.modal-button {
	padding: 10px 40px;
	border-radius: 10px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: var(--accent-color);
	color: white;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	user-select: none;
}

.modal-button:hover {
	transform: scale(1.05);
	filter: brightness(1.08);
}
