.home {
	min-height: 100vh;
	display: flex;
}

.main-content {
	background-color: var(--secondary-background-color);
	padding: 2rem;
	display: flex;
	flex-grow: 1;
	overflow-y: auto;
	flex-direction: column;
	align-items: center;
}
