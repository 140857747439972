.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.header-title {
	margin: 0;
	color: var(--text-color-secondary)
}

.header-button {
	padding: 10px 40px;
	border-radius: 10px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: red;
	color: white;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	user-select: none;
}

.header-button:hover {
	transform: scale(1.05);
	filter: brightness(1.08);
}
