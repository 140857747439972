.sign-form {
	border: 1px solid var(--text-color-secondary);
	max-width: 450px;
	width: 100%;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem;
	background-color: var(--secondary-background-color);
	box-shadow: 0px 0px 100px 10px rgba(246, 76, 114, 0.6);
}

.title {
	color: var(--text-color-secondary);
	padding: 0;
	margin: 0;
	font-size: 2rem;
	font-family: var(--font-secondary);
	margin-top: 30px;
}

.subheading {
	color: var(--text-color-detail);
	padding: 0;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
}

.form {
	width: 100%;
}

.sign-inputs {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	width: 100%;
	margin-top: 40px;
}

.sign-input {
	margin: 0;
}

.sign-button {
	padding: 12px;
	width: 100%;
	border-radius: 50px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: var(--text-color-secondary);
	cursor: pointer;
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.sign-additional {
	margin-top: 64px;
	display: flex;
	justify-content: center;
	gap: 10px;
	color: var(--text-color-detail);
}

.sign-link {
	text-decoration: none;
	color: var(--accent-color);
}

.error-message {
	color: var(--accent-color);
}
