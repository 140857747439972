.hero {
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.hero-content {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.hero-heading {
	margin: 0;
	color: var(--primary-background-color);
	font-family: var(--font-secondary);
	font-weight: 900;
	font-size: 160px;
	line-height: 120px;
	user-select: none;
}

.hero-sub-heading {
	color: var(--secondary-background-color);
	font-size: 30px;
	text-align: center;
	font-family: var(--font-secondary);
	user-select: none;
}

.hero-buttons {
	display: flex;
	gap: 10px;
}

.hero-button {
	padding: 10px 40px;
	border-radius: 10px;
	outline: none;
	border: none;
	font-weight: 500;
	background-color: var(--accent-color);
	color: white;
	font-size: 20px;
	margin-top: 35px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	user-select: none;
}

.hero-button:hover {
	transform: scale(1.05);
	filter: brightness(1.08);
}

.hero-wrapper {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
}

.hero-gradient {
	width: 100%;
	height: 100%;
	position: absolute;
}

.hero-img {
	object-fit: cover;
	min-height: 100%;
	min-width: 100%;
}
